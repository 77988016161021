import React from "react"
import styled from "styled-components"
import { darken } from "polished"
import { lightGray } from "mill/utils/colors"
import { lg } from "mill/utils/breakpoints"
const white = "#ffffff"

const Bar = styled.div`
  position: relative;
  height: 0.5rem;
  border-radius: ${props => (props.square ? "0rem" : "0.5rem")};
  width: 100%;
  background: ${darken(0.05, white)};
  border-bottom: 1px solid ${darken(0.1, lightGray)};
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  margin-top: 0.5rem;

  @media ${lg} {
    height: 0.7rem;
    border-radius: ${props => (props.square ? "0rem" : "0.7rem")};
  }
`

const Progress = styled.div`
  width: ${props => (props.percentage ? props.percentage : 0)}%;
  max-width: 100%;
  height: 0.5rem;
  border-radius: ${props => (props.square ? "0rem" : "0.5rem")};
  background: ${props =>
    props.fillColor && props.theme.colors[props.fillColor]
      ? props.theme.colors[props.fillColor]
      : props.theme.colors.primary};
  transition: width 300ms ease-in-out;
  @media ${lg} {
    height: 0.7rem;
    border-radius: ${props => (props.square ? "0rem" : "0.7rem")};
  }
`

const ProgressBar = props => {
  return (
    <Bar square={props.square}>
      <Progress
        square={props.square}
        fillColor={props.fillColor}
        percentage={props.percentage}
      />
    </Bar>
  )
}

export default ProgressBar
