import React from "react"
import styled from "styled-components"
import ProgressBar from "mill/components/ProgressBar"
import { md } from "mill/utils/breakpoints"

const Information = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 1rem;
  right: 1rem;
  bottom: 0;
  margin-top: 1rem;
`

const Performance = styled.h4`
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 1.3rem;
  letter-spacing: 1px;
`

const Title = styled.h4`
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 1.3rem;
  color: rgb(129, 129, 129);
  @media ${md} {
    font-size: 1.5rem;
  }
`

const Container = styled.div`
  position: relative;
`

const TopicPerformanceWidget = props => {
  const toShow = props.limit
    ? props.topicPerformance.slice(0, props.limit)
    : props.topicPerformance

    return (
    <Container>
      {toShow.map(topic => {
        return (
          <div key={topic.id} style={{ marginBottom: "1.5rem" }}>
            <Information>
              <Title>{topic.name}</Title>
              <Performance>{topic.performance}%</Performance>
            </Information>
            <ProgressBar
              percentage={topic.performance}
              fillColor={props.fillColor}
            />
          </div>
        )
      })}
    </Container>
  )
}

export default TopicPerformanceWidget
